import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: '(Senior) UI / UX Designer',
  introTitle: '(Senior) UI / UX Designer (m/w/d) - in Vollzeit',
  introInfo: '<p>Die freshcells systems engineering GmbH ist eine in Düsseldorf ansässige Softwareagentur mit einem Team von mehr als 40 Mitarbeitern. Wir bieten innovative Technologie-Beratung, erstellen Software-Konzepte sowie -Strategien und entwickeln moderne und zukunftsträchtige Applikationen und Systeme für etablierte Unternehmen, Start-Ups und eigene Produkte.</p>',
  emailInfo: 'Zur Verstärkung unseres Teams suchen wir einen erfahren UI / UX Designer (m/w/d) für die Entwicklung komplexer Webapplikationen. Wenn du Lust auf ein breit gefächertes Portfolio an Projekten hast, dich stetig weiterentwickeln willst und gerne selbstständig und verantwortungsvoll arbeitest, meld dich bei uns:',
  responsibilitiesTitle: 'Deine Aufgaben',
  responsibility1: 'UX-Konzeption von komplexen Webapplikationen sowie nachhaltige und ansprechende UI Visualisierung',
  responsibility2: 'Analyse von bestehenden Produkten/Prozessen sowie deren Usability- und Conversion-Optimierung',
  responsibility3: 'Selbstständige Definition von notwendigen Arbeitsschritten zur Entwicklung digitaler Produkte',
  responsibility4: 'Einschätzung und Priorisierung von Aufgaben mit dem höchsten Mehrwert für Business- & Use-cases',
  responsibility5: 'Enge Zusammenarbeit mit einem Team von Experten aus allen Bereichen der Web- & Produktentwicklung',
  responsibility6: 'Präsentation deiner Arbeitsergebnisse vor internen und externen Stakeholdern',
  responsibility7: 'Kreativer Umgang mit modernsten Web-Technologie',
  qualificationsTitle: 'Was du mitbringst',
  qualification1: 'Mindestens 5 Jahre Erfahrung im Bereich UI/UX Design',
  qualification2: 'Ob Quereinsteiger, abgeschlossenes Studium oder Ausbildung, am Ende zählen die Erfahrungen, die du mitbringst',
  qualification3: 'Ausgeprägtes Verständnis für den Umgang mit UI-Pattern und Designsystemen sowie Erfahrung mit ihrer konsistenten Anwendung und Weiterentwicklung',
  qualification4: 'Erfahrener Umgang mit Design-Tools wie figma, sowie Prototyping',
  qualification5: 'Grundlegende Kenntnisse von Webtechnologie um zu wissen was funktioniert und was nicht',
  qualification6: 'Großes Interesse an der digitalen Welt, eCommerce, Start-ups und dem B2B Tech (Prozesssoftware) sowie dem SaaS Bereich',
  qualification7: 'Präsentations- & Managementerfahrung im Umgang mit Stakeholdern',
  qualification8: 'Sehr gute Deutsch und Englisch-Kenntnisse'
})
